import Swiper from 'swiper';

export function index(){
    const indexswipe =  document.getElementById('indexSlide');
    if(indexswipe){
		const progress = document.getElementById('progressbar');
		const first_txt = document.getElementsByClassName('first-number');
		const last_txt  = document.getElementsByClassName('last-number');
        const border = document.getElementById('swipe-border');
        const catches = document.getElementById('catch');
        
		let flag = false;
		const swiper = new Swiper('#indexSlide',{
            autoplay: {
            	delay: 6000,
            	disableOnInteraction: true,
            },
            slidesPerView: 1,
            centeredSlides: false,
			speed: 1000,
            spaceBetween: 0,
            loop: true,
            simulateTouch: false,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			},
			on: {
				init: function(){
					indexswipe.style.height = Math.floor(window.innerHeight) + "px";
					progress.classList.add('active','first');
					last_txt[0].innerText = this.slides.length - 2;
					flag = true;
                    setTimeout(function(){
                        catches.classList.add('active');
                    },1200);
				},
				slideChange: function(){
					if(flag){
						indexswipe.style.height = Math.floor(window.innerHeight) + "px";
						progress.classList.remove('active','first');
						setTimeout(function(){					
							if(document.getElementById('progressbar')){
								progress.classList.add('active');
								first_txt[0].innerText = swiper.realIndex + 1;
							}
						},200);
					}
				},
                transitionStart: function(){
                    border.classList.add('active','first');
                },
                transitionEnd: function(){
                    border.classList.remove('active','first');
                }
			},
        });
    }
}